import { BrowserRouter, Routes, Route, useLocation  } from "react-router-dom";

import Welcome from "./components/Welcome";
import Login from "./components/Login";
import Register from "./components/Register";
import { ProtectedRoute } from "./components/ProtectedRoute";
import React, { useState, useEffect  } from "react";
import styles from './App.module.scss';

const App = () => {
  const initialToken = localStorage.getItem("tokenUser");
  const [token, setToken] = useState(initialToken);
  return (
    <BrowserRouter>
      <div className={styles.container}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/welcome"
            element={
              <ProtectedRoute
                redirectTo="/login"
                isAllowed={!!initialToken && initialToken != "null"}
              >
                <Welcome />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
