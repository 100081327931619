import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";

const Welcome = () => {
  const [name, setName] = useState();

  const navigate = useNavigate();


  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      axios
        .get(`https://apileydedatos.gruporadical.com/user`, {
          headers: {
            token: token,
          },
        })
        .then(({ data }) => setName(data.nombre))
        .catch((error) => console.error(error));
    }
  }, [token]);
  const [htmlContent, setHtmlContent] = useState('');

  /*useEffect(() => {
    axios.get('http://localhost:4000/').then((response) => {
      setHtmlContent(response.data);
    });
  }, []);*/
  const [formData, setFormData] = useState({
    companyName: '',
    username: '',
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = await fetch('https://apileydedatos.gruporadical.com/download', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'documentos.zip';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } else {
      alert('Hubo un problema al descargar los documentos');
    }
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value,
    });
  };


  return (
    
    <div>
      <div className="container mt-2">
       <div class="header">
        <div class="menu-toggle">
        
      <header className="bg-dark text-white py-3">
      <ul class="menu list-unstyled d-flex">
        <li className="mr-3"><a href="#" style={{ color: 'orange' }}>Mi Cuenta</a></li>
        <li><a href="https://leydedatos.gruporadical.com/" onClick={() => localStorage.setItem("tokenUser", null)} style={{ color: 'orange' }}>Cerrar Sesión</a></li>
      </ul>

       <div className="container">
       </div>
       </header>
      <img
        src="/Login.jpeg"
        alt="Grupo Radical"
        className="img-fluid mt-3"
      />
      <button className="btn btn-warning mt-3" style={{ backgroundColor: '#fd7e14', color: 'black' }} data-toggle="modal" data-target="#myModal">
          Adquirir Documentos
        </button>
        </div>
       </div>

       <footer className="footer mt-5 text-center" style={{ backgroundColor: 'black', color: 'orange' }}>
       <span>&copy; 2023. Todos los derechos reservados. Grupo Radical</span>
       </footer>
       
        <div className="modal fade" id="myModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Información de la Empresa</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form id="myForm" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="companyName">Nombre de la Empresa:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="companyName"
                      maxLength="35"
                      placeholder="Máximo 35 caracteres"
                      value={formData.companyName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="username">Nombre de Usuario:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      value={formData.username}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <button type="submit " style={{ backgroundColor: 'orange', color: 'black' }} className="btn btn-primary btn-orange mt-3">
                    Guardar y Descargar
                  </button>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="loading-overlay" id="loadingOverlay">
        <div className="loading-spinner"></div>
      </div>
    </div>
  );
};

export default Welcome;
